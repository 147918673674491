import React, { useEffect, useRef, useState } from "react";
import { Loading } from "@src/common/components";
import {
  Driver,
  Maybe,
  OrderingAlgorithmType,
  Shipment,
  Stop,
  useGetDriverDefaultRoutingConfigurationsLazyQuery,
} from "@api/graphql/generated/generated-types";
import { ReactSortable } from "react-sortablejs";
import classNames from "classnames";
import { validateRouteDetailsOrdering } from "@src/shipments/utils/TaskListOrderingValidator";
import {
  DriverRouteDataProvider,
  useDriverRouteDataProvider,
} from "./contexts/DriverRouteDataProviderContext";
import { TaskStopCardComponent } from "./components/TaskStopCardComponent";
import MapOnDriverRouteView from "./components/MapOnDriverRouteView";
import {
  DriverRouteViewSelectionProvider,
  useDriverRouteViewSelectionProvider,
} from "./contexts/DriverRouteViewSelectionProvider";
import {
  DefaultTabOrder,
  DispatchTabOrder,
  orderingAlgorithmDisplayNames,
} from "./utils/tabConstants";
import { formatTitleCaseWithUnderscores } from "@src/common/lib/textUtils";
import { isDateStringToday } from "@src/common/lib/DateUtils";
import moment from "moment";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { useLocationIds } from "./hooks/useLocationIds";
import RouteSettingsPanel from "./components/RouteSettingsPanel";

type Props = {
  loading: boolean;
  routeDetails: Stop[] | undefined;
  setRouteDetails: (value: Stop[] | undefined) => void;
  setIsValidOrder: (value: boolean) => void;
  isValidOrder: boolean;
  selectedShipment?: Shipment | undefined;
  driver?: Maybe<Driver> | undefined;
  setSelectedOrderingAlgorithmType?: (value: OrderingAlgorithmType) => void;
  selectedOrderingAlgorithmType?: OrderingAlgorithmType;
  dispatchDriverMode?: boolean;
  setAutoRoutedStopIds?: (value: undefined | string[]) => void;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string | null>>;
  selectedStopDate?: string;
  onClickManual?: any;
  setLoading?: (value: boolean) => void;
};

function ChangeDriverRouteView({
  loading,
  routeDetails,
  setRouteDetails,
  setIsValidOrder,
  isValidOrder,
  selectedShipment,
  driver,
  setSelectedOrderingAlgorithmType: setSelectedOrderingAlgorithmTypeForParent,
  selectedOrderingAlgorithmType: selectedOrderingAlgorithmTypeForParent,
  dispatchDriverMode = false,
  setAutoRoutedStopIds,
  setErrorMessage,
  selectedStopDate,
  onClickManual,
  setLoading,
}: Props) {
  const { showToast } = useCourieStore();
  const {
    previewEtaForRouteMutation,
    optimizeRouteForDispatchMutation,
    PreviewEtaForRouteMutationData,
    OptimizeRouteForDispatchMutationData,
    OptimizeRouteForDispatchMutationLoading,
  } = useDriverRouteDataProvider();
  const {
    setSelectedOrderingAlgorithmType,
    selectedOrderingAlgorithmType,
    setSelectedBaseTime,
    selectedBaseTime,
  } = useDriverRouteViewSelectionProvider();

  const [getDriverDefaultRoutingConfigurations] =
    useGetDriverDefaultRoutingConfigurationsLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight - 50);
  const OrderingAlgorithmTypes = Object.values(OrderingAlgorithmType);
  const tabOrder = dispatchDriverMode ? DispatchTabOrder : DefaultTabOrder;
  const [isRefetching, setIsRefetching] = useState(false);
  const isRefetchingRef = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (selectedOrderingAlgorithmTypeForParent) {
      setSelectedOrderingAlgorithmType(selectedOrderingAlgorithmTypeForParent);
    }
  }, [selectedOrderingAlgorithmTypeForParent]);

  useEffect(() => {
    if (dispatchDriverMode) {
      if (selectedOrderingAlgorithmType !== OrderingAlgorithmType.Manual) {
        const selectedOrderingAlgorithmTypeIndex = tabOrder.findIndex(
          (type) => type === selectedOrderingAlgorithmType
        );
        handleOrderingAlgorithmChange(selectedOrderingAlgorithmTypeIndex);
      } else {
        if (selectedOrderingAlgorithmTypeForParent) {
          const selectedOrderingAlgorithmTypeIndex = tabOrder.findIndex(
            (type) => type === selectedOrderingAlgorithmTypeForParent
          );
          handleOrderingAlgorithmChange(selectedOrderingAlgorithmTypeIndex);
          return;
        }
        const endOrderingAlgorithmIndex = tabOrder.findIndex(
          (type) => type === OrderingAlgorithmType.End
        );
        handleOrderingAlgorithmChange(endOrderingAlgorithmIndex);
      }
    } else {
      if (selectedOrderingAlgorithmType === OrderingAlgorithmType.Manual) {
        onClickManual && onClickManual();
      }

      setSelectedOrderingAlgorithmType(selectedOrderingAlgorithmType);
    }
  }, [
    selectedStopDate,
    selectedOrderingAlgorithmType,
    selectedShipment,
    selectedBaseTime,
  ]);

  useEffect(() => {
    if (selectedStopDate && !isDateStringToday(selectedStopDate)) {
      if (!driver) return;
      getDriverDefaultRoutingConfigurations({
        variables: {
          driverIds: [driver.id],
          dates: selectedStopDate,
        },
      }).then((res) => {
        if (res.data) {
          const driverDefaultRoutingConfigurations =
            res.data.driverDefaultRoutingConfigurations[0];
          setSelectedBaseTime(
            moment.unix(driverDefaultRoutingConfigurations.startTime).toDate()
          );
        }
      });
    } else {
      setSelectedBaseTime(new Date());
    }
  }, [selectedStopDate]);

  useEffect(() => {
    if (!selectedOrderingAlgorithmType) {
      if (!dispatchDriverMode) {
        setSelectedOrderingAlgorithmType(OrderingAlgorithmType.Manual);
      } else {
        setSelectedOrderingAlgorithmType(OrderingAlgorithmType.End);
      }
    } else {
      if (!dispatchDriverMode) {
        setSelectedOrderingAlgorithmType(OrderingAlgorithmType.Manual);
      }
    }
    if (mapContainerRef.current) {
      const updatedHeight = mapContainerRef.current.offsetHeight;
      setViewportHeight(updatedHeight);
    }
    return () => {
      setRouteDetails([]);
    };
  }, [dispatchDriverMode]);

  useEffect(() => {
    if (!routeDetails || routeDetails.length === 0) {
      return;
    }

    checkRouteOrder(routeDetails);
    updateEtaForRouteDetails(routeDetails);
  }, [routeDetails, driver]);

  useEffect(() => {
    if (!OptimizeRouteForDispatchMutationData) {
      return;
    }
    setLoading && setLoading(false);
    const optimizedStops = OptimizeRouteForDispatchMutationData
      ?.optimizeRouteForDispatch.stops as Stop[];
    const optimizedStopIds = optimizedStops?.map((stop) => stop.id);
    setAutoRoutedStopIds && setAutoRoutedStopIds(optimizedStopIds);
    if (optimizedStops) {
      checkRouteOrder(optimizedStops);
      setRouteDetails(optimizedStops);
      updateEtaForRouteDetails(optimizedStops);
    }
  }, [OptimizeRouteForDispatchMutationData]);

  const updateEtaForRouteDetails = (routeDetails: Stop[], date?: Date) => {
    if (!driver) return;

    const stopIds = routeDetails.map((routeDetail) => routeDetail.id);
    let futureDate: Date | undefined;

    if (!isDateStringToday(selectedStopDate) && date) {
      const selectedDate = moment(selectedStopDate, "YYYY-MM-DD");
      const combinedDate = selectedDate
        .hour(date.getHours())
        .minute(date.getMinutes())
        .second(date.getSeconds())
        .toDate();

      setSelectedBaseTime(combinedDate);
      futureDate = combinedDate;
    }

    const baseTime = futureDate ?? date ?? selectedBaseTime;
    const baseTimeInSeconds = Math.floor(baseTime.getTime() / 1000);

    previewEtaForRouteMutation({
      variables: {
        driverId: driver.id,
        stopIds: stopIds,
        baseTime: baseTimeInSeconds,
      },
    });
  };

  const checkRouteOrder = (routeDetails: Stop[]) => {
    const { isValidOrder, errorMessage } =
      validateRouteDetailsOrdering(routeDetails);

    if (!isValidOrder) {
      setErrorMessage && setErrorMessage(errorMessage || "Invalid route order");
      setIsValidOrder(false);
      return;
    } else {
      setIsValidOrder(true);
      setErrorMessage && setErrorMessage(null);
    }
  };

  const handleOrderingAlgorithmChange = (
    index: number,
    date?: Date,
    startLocationId?: string,
    endLocationId?: string
  ) => {
    if (
      !selectedShipment ||
      !driver ||
      index < 0 ||
      index >= OrderingAlgorithmTypes.length
    ) {
      return;
    }

    if (isRefetchingRef.current) {
      // Cancel the previous API call if it's still in progress
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Cancel the previous API call
      }
    }

    let futureDate: Date | undefined;

    if (!isDateStringToday(selectedStopDate) && date) {
      const selectedDate = moment(selectedStopDate, "YYYY-MM-DD");
      const combinedDate = selectedDate
        .hour(date.getHours())
        .minute(date.getMinutes())
        .second(date.getSeconds())
        .toDate();

      setSelectedBaseTime(combinedDate);
      futureDate = combinedDate;
    }

    const baseTime = futureDate ?? date ?? selectedBaseTime;
    const baseTimeInSeconds = Math.floor(baseTime.getTime() / 1000);

    const selectedOrderingAlgorithmType = tabOrder[index];
    setSelectedOrderingAlgorithmType(selectedOrderingAlgorithmType);
    setSelectedOrderingAlgorithmTypeForParent &&
      setSelectedOrderingAlgorithmTypeForParent(selectedOrderingAlgorithmType);
    if (selectedOrderingAlgorithmType !== OrderingAlgorithmType.Manual) {
      setIsRefetching(true);
      setLoading && setLoading(true);
      isRefetchingRef.current = true; // Update the ref
      // Create a new AbortController for the upcoming request
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;
      optimizeRouteForDispatchMutation({
        variables: {
          newShipmentId: selectedShipment.id,
          driverId: driver.id,
          orderingAlgorithmType: selectedOrderingAlgorithmType,
          stopDate: selectedStopDate ? selectedStopDate : undefined,
          baseTime: baseTimeInSeconds,
          startLocationId:
            selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized
              ? startLocationId
              : undefined,
          endLocationId:
            selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized
              ? endLocationId
              : undefined,
        },
        context: {
          fetchOptions: {
            signal: newAbortController.signal,
          },
        },
      })
        .then(() => {
          if (!isRefetchingRef.current) {
            setIsRefetching(false);
            setLoading && setLoading(false);
            setErrorMessage && setErrorMessage(null);
            setIsValidOrder(true);
          }
        })
        .catch((error) => {
          if (error.message.includes("aborted")) {
            return;
          } else {
            showErrorToast(error, showToast);
            setLoading && setLoading(false);
            setIsRefetching(false);
            setErrorMessage && setErrorMessage("Something went wrong");
            setIsValidOrder(false);
          }
        })
        .finally(() => {
          isRefetchingRef.current = false;
          abortControllerRef.current = null; // Reset the abort controller
        });
    }
  };

  return (
    <Loading loading={loading} className="h-full grid grid-rows-[auto,1fr]">
      <div className="flex gap-2 row-span-2">
        <Loading
          loading={OptimizeRouteForDispatchMutationLoading}
          text="Optimizing route"
          className="w-1/3"
        >
          {routeDetails && (
            <ReactSortable
              animation={300}
              ghostClass="ghost"
              list={routeDetails.map((x) => ({ ...x, chosen: true }))}
              setList={setRouteDetails}
              scrollSensitivity={400}
              options={{
                scrollSensitivity: 400,
              }}
              className={classNames({
                "sortable-list border border-b-8 border-t-8  bg-gray-100  p-2 row-span-2 overflow-auto rounded-md w-full h-full":
                  true,
                "border-red-400": !isValidOrder,
                "border-slate-200": isValidOrder,
              })}
            >
              {routeDetails.map((task, index) => {
                const stop = task as Stop;
                const eta =
                  PreviewEtaForRouteMutationData?.previewEtaForRoute?.stopEtas?.find(
                    (stopEta) => stopEta.stopId === stop.id
                  );
                return (
                  <TaskStopCardComponent
                    key={index}
                    task={task}
                    index={index}
                    stop={stop}
                    selectedShipment={selectedShipment}
                    eta={eta}
                    selectedStopDate={selectedStopDate}
                  />
                );
              })}
            </ReactSortable>
          )}
        </Loading>
        <div className="w-2/3 flex relative" ref={mapContainerRef}>
          <div className="absolute z-10 w-full flex justify-center backdrop-blur-lg px-20 py-0.5">
            {tabOrder.map((algorithmType, key) => {
              const displayName =
                orderingAlgorithmDisplayNames[algorithmType] ||
                formatTitleCaseWithUnderscores(algorithmType);

              return (
                <span
                  className={`flex-1 text-sm font-medium leading-5 py-2 text-center rounded-lg ${
                    OptimizeRouteForDispatchMutationLoading
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  } ${
                    selectedOrderingAlgorithmType === algorithmType
                      ? "bg-white text-blue-700 shadow ring-2 ring-white ring-offset-2 ring-offset-blue-400"
                      : "text-slate-800 hover:bg-white/[0.12] hover:text-primary-600"
                  }`}
                  key={key}
                  onClick={() => {
                    if (OptimizeRouteForDispatchMutationLoading) {
                      return;
                    }
                    const index = tabOrder.findIndex(
                      (type) => type === algorithmType
                    );
                    handleOrderingAlgorithmChange(index);
                  }}
                >
                  {displayName}
                </span>
              );
            })}
          </div>

          <RouteSettingsPanel
            handleOrderingAlgorithmChange={handleOrderingAlgorithmChange}
            updateEtaForRouteDetails={updateEtaForRouteDetails}
            routeDetails={routeDetails}
            tabOrder={tabOrder}
          />

          <MapOnDriverRouteView
            routeDetails={routeDetails}
            viewportHeight={`${viewportHeight}`}
            driver={driver}
          />
        </div>
      </div>
    </Loading>
  );
}

function withDriverRouteDataProviderContext(Component: any) {
  return function WrappedComponent(props: Props) {
    if (!props) {
      return null;
    }
    return (
      <DriverRouteDataProvider>
        <DriverRouteViewSelectionProvider>
          <Component {...props} />
        </DriverRouteViewSelectionProvider>
      </DriverRouteDataProvider>
    );
  };
}

export default withDriverRouteDataProviderContext(ChangeDriverRouteView);
