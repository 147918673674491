import {
  DispatchSource,
  OrderingAlgorithmType,
  Stop,
  Task,
  TaskStatus,
  useGetDriverTasksByDriverIdLazyQuery,
  useReplaceRouteMutation,
} from "@api/graphql/generated/generated-types";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
  DriverAvatar,
  Loading,
} from "@src/common/components";
import { useContext, useEffect, useState } from "react";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import { SIZE } from "baseui/modal";
import ChangeDriverRouteView from "@src/common/components/ChangeDriverRouteView/ChangeDriverRouteView";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { getUniqueStopDates } from "@src/common/components/Widgets/DispatchCandidatesWidget/utils/assignDriverModalUtils";
import CourieButton from "@src/common/components/Button";
import StopDateListTimeline from "@src/common/components/ChangeDriverRouteView/components/StopDateListTimeline";
import { AuthContext } from "@src/auth/components/AuthProvider";

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function ChangeRoutingModal({ isModalOpen, onClose, onSuccess }: Props) {
  const { courieUser, isEmployee } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const { shipmentSelectedForAction } = useShipmentSelectionContext();
  const [routeDetails, setRouteDetails] =
    useState<Stop[] | undefined>(undefined);
  const [isValidOrder, setIsValidOrder] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [listStopDates, setListStopDates] = useState<string[]>([]);
  const [selectedStopDate, setSelectedStopDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderingAlgorithmType, setSelectedOrderingAlgorithmType] =
    useState<OrderingAlgorithmType | undefined>(OrderingAlgorithmType.Manual);
  const [autoRoutedStopIds, setAutoRoutedStopIds] =
    useState<undefined | string[]>(undefined);

  const isLastStopDate =
    listStopDates === undefined ||
    listStopDates.length === 0 ||
    listStopDates.indexOf(selectedStopDate) === listStopDates.length - 1;

  const [
    getDriverTasksByDriverId,
    {
      data: GetDriverTasksByDriverIdData,
      loading: GetDriverTasksByDriverIdLoading,
      error: GetDriverTasksByDriverIdError,
      refetch: GetDriverTasksByDriverIdRefetch,
    },
  ] = useGetDriverTasksByDriverIdLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    replaceRouteMutation,
    {
      data: ReplaceRouteMutationData,
      loading: ReplaceRouteMutationLoading,
      error: ReplaceRouteMutationError,
    },
  ] = useReplaceRouteMutation();

  useEffect(() => {
    if (shipmentSelectedForAction) {
      const orderStops = shipmentSelectedForAction.stops || [];
      const filteredStops = orderStops.filter(
        (stop) => stop.completedAt === null
      );
      const listStopDates: undefined | string[] =
        getUniqueStopDates(filteredStops);
      if (listStopDates && listStopDates.length > 1) {
        setListStopDates(listStopDates);
        setSelectedStopDate(listStopDates[0]);
      } else {
        if (listStopDates && listStopDates.length === 1) {
          if (shipmentSelectedForAction?.shipmentDate !== listStopDates[0]) {
            setSelectedStopDate(listStopDates[0]);
            setListStopDates(listStopDates);
            return;
          }
        }
        setListStopDates([]);
        setSelectedStopDate(shipmentSelectedForAction?.shipmentDate);
      }
    }
  }, [shipmentSelectedForAction]);

  useEffect(() => {
    const driverId = shipmentSelectedForAction?.driver?.id;
    if (!driverId || !isModalOpen || !selectedStopDate) {
      return;
    }
    const shipmentDate = selectedStopDate;
    getDriverTasksByDriverId({
      variables: {
        driverId: driverId,
        date: shipmentDate,
        statusFilter: [TaskStatus.Created, TaskStatus.Started],
      },
    }).catch((error) => {
      showErrorToast(error, showToast);
    });
  }, [shipmentSelectedForAction, isModalOpen, selectedStopDate]);

  useEffect(() => {
    if (GetDriverTasksByDriverIdLoading) {
      return;
    }
    if (GetDriverTasksByDriverIdData) {
      updateRouteDetails();
    }
  }, [GetDriverTasksByDriverIdData, GetDriverTasksByDriverIdLoading]);

  const onSubmit = () => {
    if (
      !shipmentSelectedForAction?.driver?.id ||
      !routeDetails ||
      !selectedStopDate
    ) {
      return;
    }
    const stopIds = routeDetails.map((stop) => stop.id);
    replaceRouteMutation({
      variables: {
        driverId: shipmentSelectedForAction.driver.id,
        newStopIds: stopIds,
        shipmentDate: selectedStopDate,
        replacedBy: isEmployee ? "EMPLOYEE" : courieUser?.id || "",
        orderingAlgorithmType: selectedOrderingAlgorithmType,
        autoRoutedStopIds: autoRoutedStopIds ? autoRoutedStopIds : [],
        dispatchSource: DispatchSource.Dispatcher,
      },
    }).then(() => {
      if (isLastStopDate) {
        onSuccess && onSuccess();
      } else {
        const nextStopDateIndex = listStopDates.indexOf(selectedStopDate) + 1;
        setSelectedStopDate(listStopDates[nextStopDateIndex]);
      }
    });
  };

  const handleCloseModal = () => {
    onClose && onClose();
  };

  const handleManualClick = () => {
    setSelectedOrderingAlgorithmType(OrderingAlgorithmType.Manual);
    if (GetDriverTasksByDriverIdData) {
      updateRouteDetails();
    }
  };

  const updateRouteDetails = () => {
    const tasks =
      (GetDriverTasksByDriverIdData?.driverById?.tasks as Task[]) || [];
    const sortedTasks = tasks.sort((a, b) => {
      return a.sequenceNumber - b.sequenceNumber;
    });
    const stops = sortedTasks.map((task) => task.stop);
    setRouteDetails(stops || []);
    const stopIds = stops.map((stop) => stop.id);
    setAutoRoutedStopIds(stopIds);
  };

  return (
    <BaseModal
      isOpen={isModalOpen}
      closeable={true}
      onClose={handleCloseModal}
      size={SIZE.auto}
      overrides={{
        Dialog: {
          style: {
            overflow: "hidden",
          },
        },
      }}
      autoFocus={false}
    >
      <BaseModalHeader>
        {" "}
        <div className="flex justify-between">
          <div className="flex gap-2">
            <DriverAvatar
              driverPhoto={shipmentSelectedForAction?.driver?.photoUrl}
              driverName={shipmentSelectedForAction?.driver?.firstName}
              size={25}
            />
            Confirm Route ({shipmentSelectedForAction?.driver?.firstName})
          </div>
          <div className="">
            <StopDateListTimeline
              listStopDates={listStopDates}
              selectedStopDate={selectedStopDate}
              shipmentDate={shipmentSelectedForAction?.shipmentDate || ""}
            />
          </div>
          <span className="mr-3"></span>
        </div>{" "}
      </BaseModalHeader>
      <BaseModalBody
        style={{ minWidth: 1200, height: "70vh", overflow: "scroll" }}
      >
        <ChangeDriverRouteView
          loading={GetDriverTasksByDriverIdLoading}
          routeDetails={routeDetails}
          setRouteDetails={setRouteDetails}
          setIsValidOrder={setIsValidOrder}
          isValidOrder={isValidOrder}
          selectedShipment={shipmentSelectedForAction}
          driver={shipmentSelectedForAction?.driver}
          setErrorMessage={setErrorMessage}
          selectedStopDate={
            listStopDates.length === 0
              ? shipmentSelectedForAction?.shipmentDate
              : selectedStopDate
          }
          onClickManual={() => {
            handleManualClick();
          }}
          setLoading={setIsLoading}
          setSelectedOrderingAlgorithmType={setSelectedOrderingAlgorithmType}
          selectedOrderingAlgorithmType={selectedOrderingAlgorithmType}
          setAutoRoutedStopIds={setAutoRoutedStopIds}
        />
      </BaseModalBody>
      <BaseModalFooter className="flex justify-between">
        {listStopDates && listStopDates.length > 1 ? (
          <CourieButton
            color={"secondary"}
            onClick={() => {
              const prevStopDateIndex =
                listStopDates.indexOf(selectedStopDate) - 1;
              if (prevStopDateIndex >= 0) {
                setSelectedStopDate(listStopDates[prevStopDateIndex]);
              } else {
              }
            }}
          >
            Back
          </CourieButton>
        ) : (
          <CourieButton color={"secondary"} onClick={handleCloseModal}>
            Cancel
          </CourieButton>
        )}
        <Loading loading={ReplaceRouteMutationLoading} className="flex">
          {errorMessage && (
            <span className="text-red-600 my-auto pr-5">{errorMessage}</span>
          )}
          <CourieButton
            isProcessing={ReplaceRouteMutationLoading || isLoading}
            onClick={onSubmit}
            disabled={!isValidOrder || isLoading || ReplaceRouteMutationLoading}
          >
            {isLastStopDate ? `Save` : "Save & Continue"}
          </CourieButton>
        </Loading>
      </BaseModalFooter>
    </BaseModal>
  );
}

export default ChangeRoutingModal;
