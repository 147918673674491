import { OrderingAlgorithmType } from "@api/graphql/generated/generated-types";

export const DefaultTabOrder = [
  OrderingAlgorithmType.Manual,
  OrderingAlgorithmType.LeastDeviation,
  OrderingAlgorithmType.OnTimeFirstReoptimized,
  OrderingAlgorithmType.Reoptimized,
  OrderingAlgorithmType.Beginning,
];

export const DispatchTabOrder = [
  OrderingAlgorithmType.LeastDeviation,
  OrderingAlgorithmType.OnTimeFirstReoptimized,
  OrderingAlgorithmType.Reoptimized,
  OrderingAlgorithmType.Beginning,
  OrderingAlgorithmType.End,
];

export const orderingAlgorithmDisplayNames = {
  LEAST_DEVIATION: "Least Deviation",
  ON_TIME_FIRST_REOPTIMIZED: "On Time",
  REOPTIMIZED: "Reroute",
  BEGINNING: "Go First",
  END: "Go Last",
  MANUAL: "Current",
};
