import React from "react";
import {
  RouteOptimizationObjectiveType,
  RouteOptimizationVehicleDistributionType,
} from "api/graphql/generated/generated-types";
import { formatTitleCaseWithUnderscores } from "@src/common/lib/textUtils";

type RoutingPenaltySettingsCreateRoutingJobSlideProps = {
  objectiveType: RouteOptimizationObjectiveType;
  setObjectiveType: (objectiveType: RouteOptimizationObjectiveType) => void;
  vehicleDistributionType: RouteOptimizationVehicleDistributionType;
  setVehicleDistributionType: (
    vehicleDistributionType: RouteOptimizationVehicleDistributionType
  ) => void;
};

function RoutingPenaltySettingsCreateRoutingJobSlide({
  objectiveType,
  setObjectiveType,
  vehicleDistributionType,
  setVehicleDistributionType,
}: RoutingPenaltySettingsCreateRoutingJobSlideProps) {
  const SelectableCard = ({ title, description, value, selectedValue, onChange }) => (
    <div
      className={`p-4 border rounded-md cursor-pointer hover:shadow-md max-w-xs ${
        value === selectedValue
          ? "bg-blue-100 border-blue-500"
          : "bg-white border-gray-300"
      }`}
      onClick={() => onChange(value)}
    >
      <h3 className="font-semibold">{title}</h3>
      <p className="text-sm text-gray-600 mt-2">{description}</p>
    </div>
  );

  const sortedVehicleDistributionTypes = Object.values(
    RouteOptimizationVehicleDistributionType
  ).sort((a, b) => {
    if (a === RouteOptimizationVehicleDistributionType.None) return -1;
    if (b === RouteOptimizationVehicleDistributionType.None) return 1;
    return a.localeCompare(b);
  });

  const makeTitleForObjectiveType = (type: RouteOptimizationObjectiveType) => {
    switch (type) {
      case RouteOptimizationObjectiveType.Distance:
        return "Distance Only";
      case RouteOptimizationObjectiveType.OnTime:
        return "On Time";
      case RouteOptimizationObjectiveType.DistanceAndOnTime:
        return "Balance Distance & On Time";
    }
  };

  const makeTitleForVehicleDistributionType = (type: RouteOptimizationVehicleDistributionType) => {
    switch (type) {
      case RouteOptimizationVehicleDistributionType.None:
        return "Choose Best Drivers";
      case RouteOptimizationVehicleDistributionType.BalancedDistribution:
        return "Balanced Workload";
    }
  };

  const makeDescriptionForVehicleDistributionType = (type: RouteOptimizationVehicleDistributionType) => {
    switch (type) {
      case RouteOptimizationVehicleDistributionType.None:
        return "Optimize driver selection as long as they return home on time. Prioritizes efficiency, but distribution may be less even sometimes.";
      case RouteOptimizationVehicleDistributionType.BalancedDistribution:
        return "Ensure even more equal workload distribution across drivers, but might result in less optimal routes."
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 min-w-[600px]">
      <div className="flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold mb-2">Optimization Goal</h2>
          <div className="grid grid-cols-3 gap-4">
            {Object.values(RouteOptimizationObjectiveType).map((type) => (
              <SelectableCard
                key={type}
                title={makeTitleForObjectiveType(type)}
                description={""}
                value={type}
                selectedValue={objectiveType}
                onChange={setObjectiveType}
              />
            ))}
          </div>
        </div>
        <div> 
          <h2 className="text-lg font-semibold mb-2">Workload Distribution</h2>
          <div className="grid grid-cols-2 gap-4">
            {sortedVehicleDistributionTypes.map((type) => (
              <SelectableCard
                key={type}
                title={makeTitleForVehicleDistributionType(type)}
                description={makeDescriptionForVehicleDistributionType(type)}
                value={type}
                selectedValue={vehicleDistributionType}
                onChange={setVehicleDistributionType}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoutingPenaltySettingsCreateRoutingJobSlide;
